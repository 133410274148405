'use client';

import { useState,useEffect } from 'react';
import Link from 'next/link';
import {
  Home,
  Settings,
  Users,
  BarChart3,
  Files,
  Menu,
} from 'lucide-react';
import { useSession, signIn, signOut } from "next-auth/react"
import { Button } from "../button/Button";
import { useRouter, redirect } from 'next/navigation';

import { LucideProps } from 'lucide-react';

const TimerPlus = ({ size = 24, color = "currentColor", strokeWidth = 2, ...props }: LucideProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M12 5C12 4.44772 12.4477 4 13 4C13.5523 4 14 4.44772 14 5V11C14 11.5523 13.5523 12 13 12C12.4477 12 12 11.5523 12 11V5Z" fill="#333333"></path>
      <path d="M28 5C28 4.44772 28.4477 4 29 4C29.5523 4 30 4.44772 30 5V11C30 11.5523 29.5523 12 29 12C28.4477 12 28 11.5523 28 11V5Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 23H12L12 25H14V23ZM12 21C10.8954 21 10 21.8954 10 23V25C10 26.1046 10.8954 27 12 27H14C15.1046 27 16 26.1046 16 25V23C16 21.8954 15.1046 21 14 21H12Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22 23H20L20 25H22V23ZM20 21C18.8954 21 18 21.8954 18 23V25C18 26.1046 18.8954 27 20 27H22C23.1046 27 24 26.1046 24 25V23C24 21.8954 23.1046 21 22 21H20Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30 23H28L28 25H30V23ZM28 21C26.8954 21 26 21.8954 26 23V25C26 26.1046 26.8954 27 28 27H30C31.1046 27 32 26.1046 32 25V23C32 21.8954 31.1046 21 30 21H28Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 31H12L12 33H14V31ZM12 29C10.8954 29 10 29.8954 10 31V33C10 34.1046 10.8954 35 12 35H14C15.1046 35 16 34.1046 16 33V31C16 29.8954 15.1046 29 14 29H12Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22 31H20L20 33H22V31ZM20 29C18.8954 29 18 29.8954 18 31V33C18 34.1046 18.8954 35 20 35H22C23.1046 35 24 34.1046 24 33V31C24 29.8954 23.1046 29 22 29H20Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 10H33C34.1046 10 35 10.8954 35 12V28C35.6906 28 36.3608 28.0875 37 28.252V12C37 9.79086 35.2091 8 33 8H9C6.79086 8 5 9.79086 5 12V36C5 38.2091 6.79086 40 9 40H28.0703C27.7122 39.381 27.4347 38.7095 27.252 38H9C7.89543 38 7 37.1046 7 36V12C7 10.8954 7.89543 10 9 10Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M36 19H6V17H36V19Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M35 42C38.3137 42 41 39.3137 41 36C41 32.6863 38.3137 30 35 30C31.6863 30 29 32.6863 29 36C29 39.3137 31.6863 42 35 42ZM35 44C39.4183 44 43 40.4183 43 36C43 31.5817 39.4183 28 35 28C30.5817 28 27 31.5817 27 36C27 40.4183 30.5817 44 35 44Z" fill="#333333"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M35 31.1787C35.5523 31.1787 36 31.6264 36 32.1787V36.4768L38.4515 38.1785C38.9052 38.4934 39.0177 39.1165 38.7027 39.5702C38.3878 40.0239 37.7647 40.1364 37.311 39.8215L34 37.5232V32.1787C34 31.6264 34.4477 31.1787 35 31.1787Z" fill="#333333"></path>
     
      </svg>

  );
};
const Interview = ({ size = 24, color = "currentColor", strokeWidth = 2, ...props }: LucideProps) => {
  return (
    <svg width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    stroke={color}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}>
      <path d="M10 29V26H13V29H10Z" fill="#fff" ></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32 44H8C5.79086 44 4 42.2091 4 40V8C4 5.79086 5.79086 4 8 4H32C34.2091 4 36 5.79086 36 8V40C36 42.2091 34.2091 44 32 44ZM18 13C18 12.4477 18.4477 12 19 12H31C31.5523 12 32 12.4477 32 13C32 13.5523 31.5523 14 31 14H19C18.4477 14 18 13.5523 18 13ZM19 16C18.4477 16 18 16.4477 18 17C18 17.5523 18.4477 18 19 18H31C31.5523 18 32 17.5523 32 17C32 16.4477 31.5523 16 31 16H19ZM15.7071 12.2929C16.0976 12.6834 16.0976 13.3166 15.7071 13.7071L11 18.4142L8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929C8.68342 13.9024 9.31658 13.9024 9.70711 14.2929L11 15.5858L14.2929 12.2929C14.6834 11.9024 15.3166 11.9024 15.7071 12.2929ZM19 24C18.4477 24 18 24.4477 18 25C18 25.5523 18.4477 26 19 26H31C31.5523 26 32 25.5523 32 25C32 24.4477 31.5523 24 31 24H19ZM18 29C18 28.4477 18.4477 28 19 28H31C31.5523 28 32 28.4477 32 29C32 29.5523 31.5523 30 31 30H19C18.4477 30 18 29.5523 18 29ZM14 24H9C8.44772 24 8 24.4477 8 25V30C8 30.5523 8.44772 31 9 31H14C14.5523 31 15 30.5523 15 30V25C15 24.4477 14.5523 24 14 24Z" ></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M44 40L41 44L38 40V22H44V40Z"  ></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M40 15H42C43.1046 15 44 15.8954 44 17V21H38V17C38 15.8954 38.8954 15 40 15Z" ></path>
    </svg>

  );
};
const InterviewScripts = ({ size = 24, color = "currentColor", strokeWidth = 2, ...props }: LucideProps) => {
  return (
    <svg width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    stroke={color}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}>
  <path d="m19.5,2c-0.28,0 -0.5,0.22 -0.5,0.5l0,4c0,0.82 0.66,1.5 1.47,1.5l4.03,0c0.67,0 0.65,-1 0,-1l-4.03,0c-0.26,0 -0.47,-0.21 -0.47,-0.5l0,-4c0,-0.28 -0.22,-0.5 -0.5,-0.5zm-11.03,-2c-0.81,0 -1.47,0.68 -1.47,1.5l0,23c0,0.82 0.66,1.5 1.47,1.5l17.06,0c0.81,0 1.47,-0.68 1.47,-1.5l0,-18c0,-0.13 -0.05,-0.26 -0.15,-0.35l-6,-6c-0.09,-0.1 -0.22,-0.15 -0.35,-0.15l-12.03,0zm0,1l11.82,0l5.71,5.71l0,17.79c0,0.29 -0.21,0.5 -0.47,0.5l-17.06,0c-0.26,0 -0.47,-0.21 -0.47,-0.5l0,-23c0,-0.29 0.21,-0.5 0.47,-0.5zm-4,3c-0.81,0 -1.47,0.68 -1.47,1.5l0,23c0,0.82 0.66,1.5 1.47,1.5l17.06,0c0.81,0 1.47,-0.68 1.47,-1.5l0,-1c0.01,-0.68 -1.01,-0.68 -1,0l0,1c0,0.29 -0.21,0.5 -0.47,0.5l-17.06,0c-0.26,0 -0.47,-0.21 -0.47,-0.5l0,-23c0,-0.29 0.21,-0.5 0.47,-0.5l1.06,0c0.67,0.01 0.67,-1.01 0,-1l-1.06,0z" id="svg_1"/>
  <path d="m9.02,10.19c0.86,0 1.7,0 2.59,0c0.95,0 1.74,0 2.76,0c0.69,0 1.69,0 2.6,0c0.95,0 1.79,0 2.71,0c0.86,0 1.74,0 2.68,0l0.9,0" fill="none" id="svg_2" stroke="#000000"/>
  <path d="m9.13,13.38c0.86,0 1.7,0 2.59,0c0.95,0 1.74,0 2.76,0c0.69,0 1.69,0 2.6,0c0.95,0 1.79,0 2.71,0c0.86,0 1.74,0 2.68,0l0.9,0" fill="none" id="svg_3" stroke="#000000"/>
  <path d="m9.13,16.67c0.86,0 1.7,0 2.59,0c0.95,0 1.74,0 2.76,0c0.69,0 1.69,0 2.6,0c0.95,0 1.79,0 2.71,0c0.86,0 1.74,0 2.68,0l0.9,0" fill="none" id="svg_4" stroke="#000000"/>
  <path d="m9.13,20.38c0.86,0 1.7,0 2.59,0c0.95,0 1.74,0 2.76,0c0.69,0 1.69,0 2.6,0c0.95,0 1.79,0 2.71,0c0.86,0 1.74,0 2.68,0l0.9,0" fill="none" id="svg_5" stroke="#000000"/>
    </svg>

  );
};

const menuItems = [
  { icon: Home, label: 'Dashboard', href: '/' },
  { icon: InterviewScripts, label: 'Templates', href: '/interview-templates' },
  { icon: BarChart3, label: 'Design', href: '/design' },
  { icon: TimerPlus, label: 'Scheduler', href: '/scheduler' },
  { icon: Interview, label: 'Interview', href: '/interview' },
  { icon: Files, label: 'Feedback', href: '/get-feedback' },
  { icon: Users, label: 'Candidate Tracker', href: '/candidate-tracker' },
  { icon: Settings, label: 'Settings', href: '/settings' },
];

export function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const router = useRouter()
  const { data: session } = useSession()
  useEffect(() => { // Use useEffect for redirect
      if (!session || !session.user) {
        const params = new URLSearchParams(window.location.search);  // Get URL parameters
        const pathname = window.location.pathname; // Get the current path
        const isPrivacyPage = pathname.includes('privacy') || pathname.includes('terms-of-service')|| pathname.includes('signin')
        if (!params.get('recorder') && !isPrivacyPage) {  // Check if recorder is NOT true
          router.push('/');
        }
      }
    }, [session, router]); // Add router as a dependency

    const authContent = () => {
        if (session) {
          return (
            <>
              {/* Invisible trigger area */}
              <div
                className="fixed left-0 top-0 z-30 h-screen w-2 cursor-pointer"
                onMouseEnter={() => setIsExpanded(true)}
              />
              
              {/* Sidebar */}
              <div
                className={`fixed left-0 top-0 z-40 h-screen bg-[rgb(255,255,255)] backdrop-blur supports-[backdrop-filter]:bg-[rgb(255,255,255)] 
                  border-r border-border/50 shadow-lg transition-all duration-300 ease-in-out w-64 
                  ${isExpanded ? 'translate-x-0' : '-translate-x-full'}`}
                onMouseLeave={() => setIsExpanded(false)}
              >
                <div className="flex h-16 items-center justify-between px-4 border-b border-border/50">
                  <h1 className="text-xl font-semibold text-primary text-gray-500">Navigation</h1>
                  <div
                    className="p-2 hover:bg-white/5 rounded-md cursor-pointer text-muted-foreground hover:text-primary transition-colors text-gray-500"
                    onClick={() => setIsExpanded(false)}
                  >
                    <Menu className="h-5 w-5" />
                  </div>
                </div>
                <div className="flex flex-col gap-1 p-3">
                  {menuItems.map((item) => {
                    const Icon = item.icon;
                    return (
                      <Link 
                        key={item.href} 
                        href={item.href}
                        className="flex items-center gap-2 px-3 py-2 text-muted-foreground hover:text-primary rounded-md hover:bg-black/5 transition-all duration-200 group relative overflow-hidden text-gray-500"
                      >
                        <div className="absolute inset-0 bg-primary/10 translate-x-[-100%] group-hover:translate-x-0 transition-transform duration-200" />
                        <Icon className="h-5 w-5 relative z-10 group-hover:text-primary transition-colors duration-200" />
                        <span className="relative z-10">{item.label}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </>
          );
        }
        return (<></>
        //   <div className="flex items-center gap-3 justify-end basis-3/7 px-5"> {/* Navigation links */}
        //        <Button accentColor='rose' onClick={() => {
        //         router.push('/auth/signin');
        //        }} style={{width:80,height:30,fontSize:'small'}}>Sign in</Button> 
        //   </div>
        )
      }

  return (<>{authContent()}</>)
}